// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.A_SomethingWentWrongError]: "Something went wrong",
  [keys.A_SelectDefaultPlaceholder]: "Select...",
  [keys.A_phoneNumber]: "Phone number",
  [keys.A_waba]: "WhatsApp Business Account",
  [keys.A_client]: "Client",
  [keys.A_partner]: "Partner",
  [keys.A_documentation]: "Documentation",
  [keys.A_email]: "Email",
  [keys.A_password]: "Password",
  [keys.A_back]: "Back",
  [keys.A_sortBy]: "Sort by",
  [keys.A_date]: "date",
  [keys.A_status]: "status",
  [keys.A_displayName]: "Display name",
  [keys.A_filterByStatus]: "filter by status",
  [keys.A_searchHere]: "Search here",
  [keys.A_account]: "Account",
  [keys.A_accountName]: "Account name",
  [keys.A_search]: "Search",
  [keys.A_cancel]: "Cancel",
  [keys.A_continue]: "Continue",
  [keys.A_close]: "Close",
  [keys.A_delete]: "Delete",
  [keys.A_edit]: "Edit",
  [keys.A_and]: "and",
  [keys.A_name]: "Name",
  [keys.A_deletePlaceholder]: "Type <DELETE> to confirm",
  [keys.A_deleteHelptextDefault]:
    "This action cannot be undone. Are you sure you want to continue?",
  [keys.A_viewDocs]: "View documentation",
  [keys.A_defaultLanguage]: "Default language",
  [keys.A_save]: "Save",
  [keys.A_learnMore]: "Learn more",
  [keys.A_audiencesLearnMore]: "Learn More",
  [keys.A_address]: "Address",
  [keys.A_description]: "Description",
  [keys.A_website]: "Website",
  [keys.A_deleteImage]: "Delete image",
  [keys.A_dragYourImage]: "Drag your image here or",
  [keys.A_browse]: "browse",
  [keys.A_supportsFormats]: "Supports jpg, png and svg",
  [keys.A_upload]: "Upload",
  [keys.A_imageMaxSize]: "The image should be under 3MB",
  [keys.A_paymentMethodCountrySelectLabel]:
    "Select your country to reveal available payment methods.",
  [keys.A_intCreditCard]: "International Credit Card",
  [keys.A_paymentMethodSelectLabel]: "Select your preferred payment method",
  [keys.A_savePaymentMethod]: "Save payment method",
  [keys.A_country]: "Country",
  [keys.A_cardExistingText]: "You have already submitted a credit card.",
  [keys.A_addAnotherCardButton]: "Add another card",
  [keys.A_creditCardFormHelptext]:
    "Please enter your credit card information below.",
  [keys.A_submitCardButton]: "Submit card",
  [keys.A_termsStep1]: "I agree to the",
  [keys.A_termsOfService]: "terms of service",
  [keys.A_termsConnect]: "and to",
  [keys.A_terms360exhibit1]: "exhibit 1 - 360dialog terms of use",
  [keys.A_terms360exhibit3]: "exhibit 3 - 360dialog pricing",
  [keys.A_waTermsStep1]: "I have acknowledged and agree to",
  [keys.A_terms360exhibit2]: "exhibit 2 - Meta client terms for WhatsApp",
  [keys.A_partnerTermsConnect]: "and the",
  [keys.A_privacyPolicy]: "privacy policy",
  [keys.A_partnersTermsAndConditions]: "terms and conditions",
  [keys.A_selectNumberTitle]: "Select number",
  [keys.A_addNewNumber]: "Add new number",
  [keys.A_or]: "or",
  [keys.A_tooltipEmbeddedSignup]:
    "Submissions using this form will follow the Embedded Signup activation flow. Activate accounts in less than 10 minutes, and finish the verification process later. <LINK>https://docs.360dialog.com/embedded-signup | Learn more.<LINK>",
  [keys.A_tooltipClassicSignup]:
    "Submissions using this form will follow the Classic activation flow. Companies must be completely verified before accessing the API. <LINK>https://docs.360dialog.com/submission-process | Learn more.<LINK>",
  [keys.A_loading]: "Loading",
  [keys.A_notImage]: "The uploaded file should be jpg, png or svg",
  [keys.A_tooltipNumberPortingSignup]:
    "Submissions using this form will allow clients to create an account via porting a number from another BSP. <LINK>https://docs.360dialog.com/360-client-hub/number-porting | Learn more.<LINK>",
  [keys.A_FbmId]: "Business Manager ID",
  [keys.A_buttonLabelReturnToHub]: "Return to 360dialog Client Hub",
  [keys.A_notificationNoPermission]:
    "You do not have permission to access this page, because this account is connected to another partner. If you wish to migrate your numbers to this partner please request a partner change in your Profile -> Organization Settings via hub.360dialog.com.",
  [keys.A_textNoPermission]:
    "You do not have permission to access this page, because this account is connected to another partner.",
  [keys.A_continueNext]: "Continue to next step",
  [keys.A_pleaseTryAgain]:
    "We’re currently experiencing issues with the Login. Please try again in 30 minutes.",
  [keys.A_clientsSearchPlaceholder]: "Search for name",
  [keys.A_terms]: "Terms",
  [keys.A_userNotExists]:
    "This user doesn't exist. Please sign up via your partner's app or 360dialog.com",
  [keys.A_verificationError]: "Something went wrong, please try again",
  [keys.A_partnerIsBlocked]: "Partner is blocked for new submissions",
  [keys.A_urlCopiedToClipboard]: "URL copied to Clipboard",
  [keys.A_latestSearch]: "Latest search",
  [keys.A_loadingBillingPortal]: "Loading billing portal",
  [keys.NOT_allNotifications]: "All",
  [keys.NOT_archivedNotifications]: "Archived",
  [keys.NOT_closeButtonTooltip]: "Archive this notification",
  [keys.NOT_archiveAllBtn]: "Archive all",
  [keys.NOT_clientAdded]: "New client will be added to your partner hub",
  [keys.A_errorDownloadingCSV]:
    "An error occured while trying to download CSV file. Please, try again later.",
  [keys.A_financialReportSentToEmail]:
    "Financial report will be sent to your email address shortly.",
  [keys.A_errorGeneratingFinancialReport]:
    "An error occured while generating the financial report. Please, try again later.",
  [keys.A_exportAllContactData]: "Export all contact data",
  [keys.A_generateFinancialReport]: "Generate financial report",
  [keys.A_generateInvoices]: "Generate invoices",
  [keys.A_templateMessages]: "Template Messages",
  [keys.A_generateTemplateInvoices]: "Generate template invoices",
  [keys.A_generateTemplateReport]: "Generate template report",
  [keys.A_conversationBasedPricing]: "Conversation Based pricing",
  [keys.A_generateCBPInvoices]: "Generate CBP invoices",
  [keys.A_generateCBPDailyReport]: "Generate CBP daily report",
  [keys.A_generateCBPMonthlyReport]: "Generate CBP monthly report",
  [keys.A_invoiceCreationTriggered]:
    "Invoice creation triggered. Invoices will be sent via email.",
  [keys.A_invoiceDateNeedsToBeFirstOrLast]:
    "The invoicing date needs to be the first or the last day of a month.",
  [keys.A_dateOfInvoice]: "Date of invoice",
  [keys.A_selectInvoicingDate]: "Select invoicing date",
  [keys.A_reportCreationTriggered]:
    "Report creation triggered. Report will be sent via email.",
  [keys.A_triggerInvoiceCreation]: "Trigger invoice creation",
  [keys.A_selectReportDate]: "Select report date",
  [keys.A_dateOfReport]: "Date of report",
  [keys.A_monthlyReportCreationTriggered]:
    "Monthly report creation triggered. Report will be sent via email.",
  [keys.A_dailyReportCreationTriggered]:
    "Daily report creation triggered. Report will be sent via email.",
  [keys.A_selectMonthlyReportDate]: "Select monthly report date",
  [keys.A_selectDailyReportDate]: "Select daily report date",
  [keys.A_somethingWentWrongnTryAgain]:
    "Something went wrong, please try again later",
  [keys.A_linkURL]: "Link URL",
  [keys.A_parameters]: "Parameters",
  [keys.A_imageRecommendation]:
    "Recommendation: max size of 5MB and image size of 640x640.",
  [keys.A_displayLogoAndDeepLinkNotice]:
    "This is how clients will see your logo and brand name in multiple places within the 360dialog Client Hub.",
  [keys.A_attentionToShowLogoAndBrandSet]:
    "The following element will only show if both company logo and brand name are set.",
  [keys.A_attentionToShowIfURLProvided]:
    "The following element will only show if a deeplink URL is provided.",
  [keys.A_companyLogo]: "Company Logo",
  [keys.A_uploadLogo]: "Upload Logo",
  [keys.A_profilePreview]: "Profile Preview",
  [keys.A_deeplinkURL]: "Deeplink URL",
  [keys.A_logoChangeSuccess]: "Logo changed successfully",
  [keys.A_deeplinkURLToolTipContent]:
    "If this value is set, users will have the option to click a button after they created their API key, which will take them back to your application, where they can place the API key.",
  [keys.A_logoAndBrandNameVariantOne]: "Variant 1: Migration Form",
  [keys.A_logoAndBrandNameVariantTwo]: "Variant 2: Logo and brand name",
  [keys.A_featured]: "Featured",
  [keys.A_verifyHuman]: "Verify you are a human",
  [keys.A_insightsAndFunds]: "Insights & funds",
  [keys.A_letsDoIt]: "Let's do it!",
  [keys.A_paymentPlan]: "Select your pricing plan",
  [keys.A_newNumberPlan]: "Select the pricing plan for the new number",
  [keys.A_betterSla]: "Better SLA",
  [keys.A_higherScalability]: "Higher Scalability",
  [keys.A_verifyBusiness]: "Verify your business!",
  [keys.A_verifyFBM]:
    "Head to Meta Business Manager to start with the business verification process.",
  [keys.A_accessCompanyFb]:
    "To continue you will need to <BOLD>access your company's Meta business account.<BOLD>",
  [keys.A_continueWithFbButton]:
    "By clicking the Continue with Meta button below you can:",
  [keys.A_addNumbers]: "Add numbers",
  [keys.A_addNumber]: "Add number",
  [keys.A_addFirstNumber]:
    "You haven't added any phone numbers yet. Add your first number to get started with the WhatsApp Business Platform.",
  [keys.A_termsAndConditions]: "Terms & Conditions",
  [keys.A_paymentDetails]: "Payment details",
  [keys.A_facebookSetup]: "Meta Setup",
  [keys.A_termsAndPriceList1]:
    "I agree to the <LINK>https://www.360dialog.com/contact#privacy-policy | 360dialog Terms of Service<LINK>, and to the",
  [keys.A_termsAndPriceList2]:
    "<LINK>https://www.360dialog.com/whatsapp-business-api?origin=new_hub#pricing | 360dialog Price List<LINK>.",
  [keys.A_user]: "User",
  [keys.A_supportPageTitle]: "360dialog - Support Tickets",
  [keys.A_supportPageDescription]: "Support and contact",
  [keys.A_numberPageTitle]: "360dialog - Your Number",
  [keys.A_numberPageDescription]: "View your number",
  [keys.A_detailsPageTitle]: "360dialog - Channel Details",
  [keys.A_detailsPageDescription]: "View details about a channel",
  [keys.A_changeDisplayName]: "Change display name",
  [keys.A_pleaseContinueRegistration]:
    "Please continue the registration of your number",
  [keys.A_continueRegistration]: "Continue registration",
  [keys.A_continueVerification]: "Continue verification",
  [keys.A_number]: "Number",
  [keys.A_numbers]: "Number",
  [keys.A_inappropriatePassword]:
    "Can't create user. Reason: Password contains user information",
  [keys.A_businessVerifiedButton]: "I’ve already verified my business",
  [keys.A_businessNotVerifiedButton]: "Continue without verification",
  [keys.A_chatSupportEnabled]: "Chat Support Enabled",
  [keys.A_acceptAndClose]: "Accept and close",
  [keys.A_fullInfoDetails]: "The full information can be accessed {{hereLink}}",
  [keys.A_here]: "here",
  [keys.A_subProcessorPopupTitle]:
    "Information on the Addition of new Sub-Processors",
  [keys.A_thatsAllNotifications]: "That's all your notifications.",
  [keys.A_errorPaymentToken]: "Error while getting payment token",
  [keys.A_maintenance]:
    "We're undergoing maintenance and will be back shortly, sorry for the inconvenience. To learn more please visit our <LINK>https://status.360dialog.com | status page.<LINK>",
  [keys.A_userMaintenance]: "User Maintenance",
  [keys.A_actions]: "Actions",
  [keys.A_signals]: "Signals",
  [keys.A_manage]: "Manage",
  [keys.A_qualityRating]: "Quality rating",
  [keys.A_variantIOTitle]: "Variant 2: Integrated Onboarding",
  [keys.A_allowClientsAddNumber]: "Allow Clients to Add Phone Number",
  [keys.A_allowClientsAddNumberTooltip]:
    "Restricts or Allows your clients to add phone numbers. This only applies to the adding of numbers through the 360dialog client hub. It doesn’t apply for Integrated Onboarding",
  [keys.A_allow]: "Allow",
  [keys.A_restrict]: "Restrict",
  [keys.A_restrictClientMessage]:
    "Do you want to restrict your clients from adding phone numbers by themselves within the 360dialog Client Hub?",
  [keys.A_allowClientMessage]:
    "Do you want to allow your clients to add phone numbers by themselves within the 360dialog Client Hub?",
  [keys.A_updatedAllowedMessage]:
    "Updated, clients can now add their phone numbers.",
  [keys.A_updatedRestrictMessage]:
    "Updated, clients can no longer add their phone numbers.",
  [keys.A_infoDetailsPriceChange]:
    "Please click {{hereLink}} to see changes in separate page.",
  [keys.A_priceChangePopupTitle]: "Information on Pricing Change Updates",
  [keys.A_selectEventType]: "Select an event type",
  [keys.A_whereWasEvent]:
    "Where was the event you want to measure against triggered",
  [keys.A_eventWithinConversation]:
    "An event that happened within the conversation",
  [keys.A_eventOutsideConversation]:
    "An event that happened outside the conversation",
  [keys.A_messagingEvent]: "Messaging event",
  [keys.A_externalEvent]: "External event",
  [keys.A_enterNumber]: "Enter the number you’d like to add.",
  [keys.A_yourNumber]: "Your number",
  [keys.A_iHaveNumber]: "I already have a number",
  [keys.A_iDontHaveNumber]: "I don’t have a number",
  [keys.A_selectDoYouHaveNumber]:
    "Select whether or not you already have a number",
  [keys.A_doYouHaveNumber]: "Do you already have a number?",
  [keys.A_youNeedNumberToFinalizeJourney]:
    "You need to have a number in order to finalise this journey.\nWe suggest you get one from any of these providers:",
  [keys.A_createNumber]: "Create number",
  [keys.A_basicSubPlan]: "Basic",
  [keys.A_regularSubPlan]: "Regular",
  [keys.A_premiumSubPlan]: "Premium",
  [keys.A_cloudAPIHosting]: "Cloud API Hosting",
  [keys.A_HostingService]: "<PERCENTAGE> Hosting Service Level",
  [keys.A_TicketBasedSupport]:
    "Ticket-based support with 24 hours response time",
  [keys.A_OnPremiseHosting]: "On-premise hosting",
  [keys.A_RTChat]:
    "Chat with real-time responses, with guaranteed investigation in 30 minutes",
  [keys.A_WABAReq]: "WhatsApp Official Business Account request",
  [keys.A_AdsFunnelTracking]: "Includes Ads & measurement Funnel Tracking",
  [keys.A_recommended]: "Recommended",
  [keys.A_choosePlan]: "Choose your plan",
  [keys.A_SelectPlan]:
    "Select a plan that offers the capabilities your business needs.",
  [keys.A_includes]: "includes",
  [keys.A_prevTierPlus]: "<PLAN>, plus:",
  [keys.A_continueWithSelectedPlan]: "Continue with <PLAN> plan",
  [keys.A_activityPageDescription]: "View Activity",
  [keys.A_activityPageTitle]: "360dialog - Activity",
  [keys.A_activity]: "Activity",
  [keys.A_noActivityFound]: "No activity found",
  [keys.A_noActivitySeen]:
    "We haven't seen any number activity under these filters.",
  [keys.A_allChannels]: "All channels",
  [keys.A_addNumberToSeeActivity]: "Add a number to see activity",
  [keys.A_addNumberOverview]:
    "Add your first number and here you will be able to overview the conversations and expenses your incurred in.",
  [keys.A_insights]: "Insights",
  [keys.A_expenseTooltip]:
    "Expenses are estimated and values are not legally binding.",
  [keys.A_downloadContacts]: "Download contacts",
  [keys.A_downloadContactsList1]: "Sending the contacts to your inbox",
  [keys.A_downloadContactsList2]:
    "We are getting the contacts you requested and will be delivering them to your email in a CSV. This process may take some time.",
  [keys.A_couldYouTellUsMore]: "Could you please tell us more? (optional)",
  [keys.A_downloadCsv]: "Download CSV",
  [keys.A_nameTheSource]: "Name the source",
  [keys.A_nameTheSourceDescription]:
    "Give the source a name that your team understands",
  [keys.A_sourceName]: "Source name",
  [keys.A_message]: "Message",
  [keys.A_saveSource]: "Save source",
  [keys.A_inviteMarketer]: "Invite a marketer",
  [keys.A_inviteMarketer1]:
    "Invite a member of your marketing team to join the project.",
  [keys.A_coworkerEmail]: "Coworker email",
  [keys.A_invite]: "Invite",
  [keys.A_anInviteWasSent]: "An invite was sent to <EMAIL>",
  [keys.A_messageDetails]: "Message details",
  [keys.A_checkSelectedMessages]: "Check selected message details.",
  [keys.A_sourceInvestment]: "Source investment",
  [keys.A_investmentMade]:
    "Investment made to capture leads during the selected period of time.",
  [keys.A_amountCurrency]: "Amount (<CURRENCY>)",
  [keys.A_investedAmount]: "Invested amount",
  [keys.A_saveInvestment]: "Save investment",
  [keys.A_requestPartnerAccess]:
    "Request access to your partner to view conversation insights. In the meantime, check the video provided above to see what you can achieve with this page!",
  [keys.A_trackEvent]: "Track an event",
  [keys.A_selectPoolConversations]:
    "Select a pool of conversations from the sources that started them. Then, select a conversion event to compare the performance.",
  [keys.A_billingPageTitle]: "360dialog - Billing",
  [keys.A_billingPageDescription]: "View and manage invoices",
  [keys.A_coworkerName]: "Coworker full name",
  [keys.A_sentTo]: "Sent to",
  [keys.A_viewConversationsInsights]: "View conversation insights",
  [keys.A_insightsPageTitle]: "360dialog - Copilot",
  [keys.A_insightsPageDescription]: "View Insights",
  [keys.A_measurePerformance]: "Measure the performance of your conversations",
  [keys.A_changeEventType]: "Change event type",
  [keys.A_adAccountsPageTitle]: "360dialog - Ad Accounts",
  [keys.A_adAccountsPAgeDescription]: "View and manage ad accounts",
  [keys.A_permissions]: "Permissions",
  [keys.A_preferences]: "Preferences",
  [keys.A_all]: "All",
  [keys.A_archived]: "Archived",
  [keys.A_archiveAll]: "Archive all",
  [keys.A_fbTokenExpired]: "Your Facebook account token expired",
  [keys.A_goToAdsAccount]: "Go to Ads Account",
  [keys.A_generalPageTitle]: "360dialog - General",
  [keys.A_invoicesPageTitle]: "360dialog - Invoices",
  [keys.A_partnerPageTitle]: "360dialog - Partner",
  [keys.A_paymentPageTitle]: "360dialog - Payment",
  [keys.A_teamPageTitle]: "360dialog - Team",
  [keys.A_fundsPageTitle]: "360dialog - Funds",
  [keys.A_funds]: "Funds",
  [keys.A_rename]: "Rename",
  [keys.A_options]: "Options",
  [keys.A_adminHeaderInfo]:
    "You are viewing as a user of the client: <client_name>. To view another client change clientId and partnerId from the URL.",
  [keys.A_funnels]: "Copilot",
  [keys.A_Integrations]: "Integrations",
  [keys.A_integrationsPageTitle]: "360dialog - Integrations",
};

export default translate;
