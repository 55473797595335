// eslint-disable-next-line import/no-anonymous-default-export
export default {
  localStoragePrefixMP: "mp",
  localStoragePrefixSettings: "__settings__",
  intervalMessages: 4000,
  intervalConversations: 8000,
  client_id: "WQ2TtPjp75UXrJ0L20NjlPho8SxWWsA4",
  staging_client_id: "45mQ6sPQEuTfWUgO59dDZbD3Y8SsngJ7",
  features: {
    supportTemplateWithParams: true,
  },
  intercomAppId: "w5qvfu58",
  partner_id: ["rc", "production"].includes(process.env.NEXT_PUBLIC_APP_ENV)
    ? "srMmqpPA"
    : "CmfFRDPA",
  companyName: ["rc", "production"].includes(process.env.NEXT_PUBLIC_APP_ENV)
    ? "360 Dialog"
    : "360 Staging",
  userpilotKey: "NX-ced8714b",
  wabaManagementUrl:
    process.env.NEXT_PUBLIC_APP_ENV === "production"
      ? "https://wabamanagement.360dialog.io"
      : process.env.NEXT_PUBLIC_APP_ENV === "rc"
      ? "https://rc-wabamanagement.360dialog.io"
      : process.env.NEXT_PUBLIC_APP_ENV === "development"
      ? "https://staging-wabamanagement.360dialog.io"
      : "http://localhost:3001",
  signalsUrl:
    process.env.NEXT_PUBLIC_APP_ENV === "production"
      ? "https://dashboard.signals.360dialog.io"
      : process.env.NEXT_PUBLIC_APP_ENV === "rc"
      ? "https://rc-dashboard.signals.360dialog.io"
      : process.env.NEXT_PUBLIC_APP_ENV === "development"
      ? "https://staging-dashboard.signals.360dialog.io"
      : "http://localhost:3002",
  domain: ["rc", "production", "development"].includes(
    process.env.NEXT_PUBLIC_APP_ENV
  )
    ? "360dialog.io"
    : "localhost",
  hubspot: {
    portalID: 508063,
    formID: {
      "after-signup": "a1bce9ce-9e4f-4ae6-b3a1-6b1ddf63540d",
      "after-emsignup": "fc41f9f1-c082-4a84-9733-c44662496407",
    },
  },
  cryptoJsDecryptionKeys: {
    key_hex_string: process.env.NEXT_PUBLIC_KEY_HEX_STRING,
    iv_hex_string: process.env.NEXT_PUBLIC_IV_HEX_STRING,
  },
  mainHubUrl:
    process.env.NEXT_PUBLIC_APP_ENV === "production"
      ? "https://hub.360dialog.com"
      : process.env.NEXT_PUBLIC_APP_ENV === "rc"
      ? "https://rc-hub.360dialog.com"
      : process.env.NEXT_PUBLIC_APP_ENV === "development"
      ? "https://admin.hub-staging.360dialog.io"
      : "https://admin.hub-staging.360dialog.io",
  clientHubUrl:
    process.env.NEXT_PUBLIC_APP_ENV === "production"
      ? "https://app.360dialog.io"
      : process.env.NEXT_PUBLIC_APP_ENV === "rc"
      ? "https://rc-app.360dialog.io"
      : process.env.NEXT_PUBLIC_APP_ENV === "development"
      ? "https://staging-app.360dialog.io"
      : "http://localhost:3000",
  mixpanel_token: "84621d6df0a8c80e1726868ea08bc00a",
  statsigClientKey: "client-iAmf7fy6WxSV7GZ7j2hhjVpuM3B5Q7G2HbkHoB4Xs9A",
  COOKIEBOT_APP_ID: "2f3d38bd-cdec-458e-ae2c-ca1c3dd09f47",
  OPEN_RELAY_PROJECT_KEY: "gnsldcDJYx1ORIxEEUUu",
  facebookAppId: "307713669880953",
  SEOMetaDescription:
    "360dialog is the ideal performance marketing & analytics tool for WhatsApp. Powered by the official WhatsApp On-premises API and Cloud API from Meta",
  authConfigId: "449239734620124",
  FB_LOGIN_CONFIG: "1747283946113431",
  appName:
    process.env.NEXT_PUBLIC_APP_ENV === "production" ||
    process.env.NEXT_PUBLIC_APP_ENV === "rc" ||
    process.env.NEXT_PUBLIC_APP_ENV === "development"
      ? "direct_hub"
      : "localhost",
  authX360UrlV1: ["rc", "production"].includes(process.env.NEXT_PUBLIC_APP_ENV)
    ? "https://authx360.hub-production.360dialog.io/v1"
    : "https://authx360.hub-staging.360dialog.io/v1",
  auth0BaseUrl: ["rc", "production"].includes(process.env.NEXT_PUBLIC_APP_ENV)
    ? "https://360dialog.eu.auth0.com"
    : "https://tpp-360dialog.eu.auth0.com",
};
