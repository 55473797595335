// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  INS_understandWhatWorksBest: "INS_understandWhatWorksBest",
  INS_trackedConversion: "INS_trackedConversion",
  INS_conversationsFrom: "INS_conversationsFrom",
  INS_whereLeads: "INS_whereLeads",
  INS_editConversions: "INS_editConversions",
  INS_edit: "INS_edit",
  INS_allSources: "INS_allSources",
  INS_incoming: "INS_incoming",
  INS_outgoing: "INS_outgoing",
  INS_organic: "INS_organic",
  INS_sourceName: "INS_sourceName",
  INS_startedConversations: "INS_startedConversations",
  INS_conversionRate: "INS_conversionRate",
  INS_convertedLeads: "INS_convertedLeads",
  INS_invested: "INS_invested",
  INS_revenue: "INS_revenue",
  INS_downloadLeads: "INS_downloadLeads",
  INS_downloadAllLeads: "INS_downloadAllLeads",
  INS_saveEvent: "INS_saveEvent",
  INS_saveEventTooltip: "INS_saveEventTooltip",
  INS_saveEventSubtitle: "INS_saveEventSubtitle",
  INS_notificationEventCreated: "INS_notificationEventCreated",
  INS_writeTheEvent: "INS_writeTheEvent",
  INS_saveEventLabel: "INS_saveEventLabel",
  INS_confirm: "INS_confirm",
  INS_downloadAllLeadsDescription: "INS_downloadAllLeadsDescription",
  INS_searchNumber: "INS_searchNumber",
  INS_leadsDescription: "INS_leadsDescription",
  INS_percentageLeads: "INS_percentageLeads",
  INS_leadsConversion: "INS_leadsConversion",
  INS_investedDescription: "INS_investedDescription",
  INS_conversionCost: "INS_conversionCost",
  INS_revenueDescription: "INS_revenueDescription",
  INS_returnDescription: "INS_returnDescription",
  INS_impressionsTitle: "INS_impressionsTitle",
  INS_impressionsDescription: "INS_impressionsDescription",
  INS_paidClicksTitle: "INS_paidClicksTitle",
  INS_paidClicksDescription: "INS_paidClicksDescription",
  INS_roasDescription: "INS_roasDescription",
  INS_learnEnable: "INS_learnEnable",
  INS_costPerConversion: "INS_costPerConversion",
  INS_revenueNotAvailable: "INS_revenueNotAvailable",
  INS_adsFrom: "INS_adsFrom",
  INS_sourceInfo: "INS_sourceInfo",
  INS_detailedInfo: "INS_detailedInfo",
  INS_typeLabel: "INS_typeLabel",
  INS_typeInfo: "INS_typeInfo",
  INS_typeDescription: "INS_typeDescription",
  INS_incomingCustom: "INS_incomingCustom",
  INS_incomingCustomDescription: "INS_incomingCustomDescription",
  INS_giveThisSourceName: "INS_giveThisSourceName",
  INS_yesterday: "INS_yesterday",
  INS_enableIt: "INS_enableIt",
  INS_steps: "INS_steps",
  INS_createNewFunnel: "INS_createNewFunnel",
  INS_nameFunnel: "INS_nameFunnel",
  INS_replaceEvent: "INS_replaceEvent",
  INS_selectEvent: "INS_selectEvent",
  INS_removeEvent: "INS_removeEvent",
  INS_mustNotExceed20: "INS_mustNotExceed20",
  INS_costPerAcquisition: "INS_costPerAcquisition",
  INS_inThreadEvent: "INS_inThreadEvent",
  INS_offThreadEvent: "INS_offThreadEvent",
  INS_funnelUpdated: "INS_funnelUpdated",
  INS_sourceTypes: "INS_sourceTypes",
  INS_sources: "INS_sources",
  INS_sourcesDescription: "INS_sourcesDescription",
  INS_messages: "INS_messages",
  INS_adSets: "INS_adSets",
  INS_adSet: "INS_adSet",
  INS_folders: "INS_folders",
  INS_campaigns: "INS_campaigns",
  INS_ads: "INS_ads",
  INS_metaClickToWhatsApp: "INS_metaClickToWhatsApp",
  INS_metaCTWA: "INS_metaCTWA",
  INS_incomingCampaigns: "INS_incomingCampaigns",
  INS_outgoingTemplates: "INS_outgoingTemplates",
  INS_open: "INS_open",
  INS_noConversations: "INS_noConversations",
  INS_connectAdAccount: "INS_connectAdAccount",
  INS_connect: "INS_connect",
  INS_selectEventToViewDetails: "INS_selectEventToViewDetails",
  INS_custom: "INS_custom",
  INS_lastWeek: "INS_lastWeek",
  INS_last2Weeks: "INS_last2Weeks",
  INS_lastMonth: "INS_lastMonth",
  INS_cancel: "INS_cancel",
  INS_apply: "INS_apply",
  INS_bestRoas: "INS_bestRoas",
  INS_bestRoasTooltip: "INS_bestRoasTooltip",
  INS_bestRoasTooltipLink: "INS_bestRoasTooltipLink",
  INS_worstRoas: "INS_worstRoas",
  INS_worstRoasTooltip: "INS_worstRoasTooltip",
  INS_worstRoasTooltipLink: "INS_worstRoasTooltipLink",
  INS_orderingFilter: "INS_orderingFilter",
  INS_orderingBtnDescription: "INS_orderingBtnDescription",
  INS_details: "INS_details",
  INS_applyFunnel: "INS_applyFunnel",
  INS_funnelApplied: "INS_funnelApplied",
  INS_applyFunnelButtonTooltip: "INS_applyFunnelButtonTooltip",
  INS_applyFunnelDisabledButtonTooltip: "INS_applyFunnelDisabledButtonTooltip",
};

export default keys;
